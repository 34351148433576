@charset "UTF-8";

@import url("../../theme/plugins/fontawesome-free/css/all.min.css");
@import url("../../theme/plugins/ionicons/css/ionicons.min.css");
@import url("../../theme/plugins/typicons.font/typicons.css");
@import url("../../theme/plugins/materialdesignicons/materialdesignicons.css");
@import url("../../theme/plugins/themify/themify.css");
@import url("../../theme/plugins/feather/feather.css");

@import url("../../theme/plugins/cryptofont/css/cryptofont.min.css");
@import url("../../theme/plugins/line-awesome/css/line-awesome.css");
@import url("../../theme/plugins/simple-line-icons/simple-line-icons.css");
/*@import url("../../theme/plugins/flag-icon-css/css/flag-icon.min.css");*/
@import url("../../theme/plugins/boxicons/css/boxicons.css");